export const faqs_top = [    
    {
        q: "Q: How long do you age your salame?",
        a: "A: We cure our salame the old-fashioned way, nice and slow for several weeks."
    },
    {
        q: "Q: What’s that white stuff on the outside of the salame chub?",
        a: "A: After the salame starts its curing in the culture room, we move it to the drying room, where we carefully control the air to keep the humidity low and temperature cool. This lets the moisture in the meat rise to the surface, giving the casing its trademark white bloom, an aromatic mold that helps “lock-in” the old-world flavor and moistness. Simply peel the casing before slicing and eating the salame chub."
    },
    {
        q: "Q: Do you have any sugar in your salame?",
        a: "A: All salame has some form of sugar to facilitate the curing process. View our product ingredient lists for more details."
    },        
    {
        q: "Q: Do you use nitrates and nitrites?",
        a: "A: We use nitrates and nitrites in some of our products. Sodium Nitrite is an important ingredient in the curing process that kills clostridium botulinum, the microorganism that causes botulism, a deadly foodborne illness."
    }
];

export const faqs_bottom = [
    {
        q: "Q: What’s the difference between salame and pepperoni?",
        a: "A: Salame is an all-pork product made with traditional salame spices. Pepperoni is a pork-and-beef product made with a zestier spice blend. Both are cured in the same fashion."
    },
    {
        q: "Q: Do I have to refrigerate my salame or pepperoni?",
        a: "A: Yes, salame and pepperoni need to be refrigerated after opening."
    }    
];

//export default faqs;