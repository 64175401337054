// import node modules
import React, { Component } from "react";

// import components
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout.component";

import { faqs_top, faqs_bottom } from "../data/Pages/Faqs.data";
import { Col, Container, Row } from "reactstrap";
import { Link } from "gatsby";

import faq_bg from "../assets/images/faqs/san-francisco-golden-gate-bridge.png";


class Faqs extends Component {
  render() {
    return (
      <Layout
        metaTitle="FAQs | Gallo Salame®"
        metaDescription="Have questions? View our FAQs to learn more about our products, our process and more." 
      >
        <Container 
        className="gagl-faq-container gagl-faq-top-container"
        style={{ backgroundImage: `url(${faq_bg})` }}
        role="img" aria-label="san francisco golden gate bridge">
          <div className="gagl-top-content">
              
              <h1 className="gagl-faq-header">Frequently asked questions</h1>

              <p className="gagl-faq-para gagl-faq-top-para">You’ve got questions, we’ve got answers.<br /> 
              <Link to="/contact-us/" className="gagl-faq-links"> Contact us </Link> if you don’t find what you’re looking for.</p>

          </div>
        </Container>

        <Container className="gagl-faq-container">
          <Row className="gagl-faq-content">
            <Col
            lg={{ size: 4, offset: 4 }}
            md={{ size: 4, offset: 4 }}
            sm={{ size: 12, offset: 0 }}
            xs={{ size: 12, offset: 0 }}>

              <div className="gagl-faq-question-box" >
                <p  className="gagl-faq-para question">
                  Q: Where can I find Gallo Salame<sup className="gagl-faqs-sup-text">®</sup> products?
                </p>
                <p  className="gagl-faq-para answer">
                  A: Search with our
                  <Link to="/where-to-buy/" className="gagl-faq-links"> store locator </Link> 
                  to find the retailer nearest you.
                </p>
              </div>

              {
                faqs_top.map((value, index) => {
                  return (<div className="gagl-faq-question-box" key={index + "_d"}>
                      <p key={index + "_q"} className="gagl-faq-para question">{value.q}</p>
                      <p key={index + "_a"} className="gagl-faq-para answer">{value.a}</p>
                    </div>)
                  })
              }

              <div className="gagl-faq-question-box" >
                <p  className="gagl-faq-para question">
                  Q: Do you make a low-fat product?
                </p>
                <p  className="gagl-faq-para answer">
                  A: Yes! Our&nbsp;
                  <Link to="/our-products/sliced-light-italian-dry-salame/" className="gagl-faq-links">
                    Light Italian Dry Salame
                  </Link> is every bit as delicious as our traditional Italian dry salame, yet it has 55% less fat and only 70 calories per serving.
                </p>
              </div>
              <div className="gagl-faq-question-box" >
                <p  className="gagl-faq-para question">
                  Q: Do you make a lower sodium product?
                </p>
                <p  className="gagl-faq-para answer">
                  A: Yes! Our&nbsp;
                  <Link to="/our-products/sliced-reduced-sodium-italian-dry-salame/" className="gagl-faq-links">
                    Reduced Sodium Italian Dry Salame
                  </Link> is perfect for anyone who is watching their sodium intake. It is every bit as delicious as our traditional Italian dry salame, but with 60% less sodium per serving.
                </p>
              </div>
              {
                faqs_bottom.map((value, index) => {
                  return (<div className="gagl-faq-question-box" key={index + "_d"}>
                      <p key={index + "_q"} className="gagl-faq-para question">{value.q}</p>
                      <p key={index + "_a"} className="gagl-faq-para answer">{value.a}</p>
                    </div>)
                  })
              }
              
              <div className="gagl-faq-question-box" >
                <p  className="gagl-faq-para question">
                  Q: How can I cook with your salame or pepperoni?
                </p>
                <p  className="gagl-faq-para answer">
                  A: Head over to our 
                  <Link to="/recipes/" className="gagl-faq-links"> recipe section </Link> 
                  and explore all the great ways to make a meal special with Gallo Salame<sup className="gagl-faqs-sup-text">®</sup> Italian meats.
                </p>
              </div>

            </Col>
          </Row>
        </Container>
        
      </Layout>
    );
  }
}

export default Faqs;
